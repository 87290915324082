<template>
  <div>
    <head-layout
      head-title="任务管理"
      :head-btn-options="headBtnOptions"
      @head-cancel="headCancel"
    ></head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <div class="tabsBox">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="填报" name="write"></el-tab-pane>
        <el-tab-pane label="汇总" name="summary"></el-tab-pane>
      </el-tabs>
    </div>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
    </grid-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {fillClassification} from "@/api/reportTasks";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      dataForm: {
        id: ''
      },
      activeName: 'write',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
      headBtnOptions: [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: "cancel",
        }
      ],
      tableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        column: [
          {
            label: "填报报表名称",
            prop: "title",
            align: "center",
          },
          {
            label: "下发时间",
            prop: "createTime",
            align: "center",
          },
          {
            label: "截至时间",
            prop: "deadlineFillingDate",
            align: "center",
          },
          {
            label: "填报人",
            prop: "person",
            align: "center",
          },
          {
            label: "填报时间",
            prop: "submissionDate",
            align: "center",
          },
          {
            label: "填报状态",
            prop: "dataStatus",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=reportingStatus`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
          },
        ],
      },
      searchColumns: [
        {
          label: '填报报表名称',
          prop: "title",
          span: 4,
          placeholder: '请输入填报报表名称'
        },
      ],
    }
  },
  mounted() {
    this.dataForm.id = this.$route.query.id;
    this.onLoad(this.page,{})
  },
  methods: {
    // 切换
    handleClick() {
      if (this.activeName == 'write') {

      }
      if (this.activeName == 'summary') {

      }
    },
    // 搜索填报设置
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空填报设置搜索条件
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
    // 获取列表数据
    onLoad() {
      this.tableLoading = true;
      fillClassification().then((res) =>{

      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>
<style lang="scss" scoped>
.tabsBox {
  background-color: #ffffff;
  padding: 12px;
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__content {
  padding: 0 !important;
  background-color: transparent;
}
</style>
