var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "任务管理",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c(
        "div",
        { staticClass: "tabsBox" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "填报", name: "write" } }),
              _c("el-tab-pane", { attrs: { label: "汇总", name: "summary" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }